<template>
    <Body class="font-primary text-primary">
        <NuxtLoadingIndicator :duration="5000" color="repeating-linear-gradient(to right,#b54761 0%,#ffcad4 100%)" />
        <OrganismsGlobalTopBar v-if="false" :is-enabled="!!topBarData" :text-color="topBarData.textColor" :background-color="topBarData.backgroundColor" :html="topBarData.text" :campaign="topBarData.campaign"></OrganismsGlobalTopBar>
        <OrganismsGlobalAppHeader></OrganismsGlobalAppHeader>
        <slot></slot>
        <OrganismsGlobalAppFooter :light="true"></OrganismsGlobalAppFooter>
    </Body>
</template>
<script setup>

if (false && !useState('topBar').value) {
    useState('topBar').value = (await useApiFetch(`/api/topBar?languageId=${useLocale().getLocale().languageId}`)).data.value.topBar;
}

const topBarData = useState('topBar').value;

</script>